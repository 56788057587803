import { Button } from "antd";
import styled from "styled-components";

export const DeleteBtn = styled(Button)`
  margin-top: 24px;
  margin-left: 12px;
  &:hover {
    background-color: red;
    color: white !important;
  }
`;
